import { isDevelopment } from '@/utility/generic';

const TestProductIds = {
  playlistPitch: {
    priceId: 'price_1OM8lnFLZGJRcZ2mb1Xs376l',
    subscribedPriceId: 'price_1OjPDAFLZGJRcZ2m2p1xnpbo',
  },
  metaAds: {
    priceId: 'price_1OjPBYFLZGJRcZ2mezHKPuv4',
  },
  tikTokAds: {
    priceId: 'price_1OjPBpFLZGJRcZ2m1y1bR9tw',
  },
  subscription: {
    priceId: {
      monthly: 'price_1OFGu0FLZGJRcZ2m8XKKgGgw',
      yearly: 'price_1Ong1mFLZGJRcZ2mXYGTNzKW',
    },
  },
};

const ProductionProductIds = {
  playlistPitch: {
    priceId: 'price_1On0XuFLZGJRcZ2mGJff2vHj',
    subscribedPriceId: 'price_1On0XuFLZGJRcZ2mD4IV3lgb',
  },
  metaAds: {
    priceId: 'price_1On0a9FLZGJRcZ2m2RWlimxQ',
  },
  tikTokAds: {
    priceId: 'price_1On0apFLZGJRcZ2m32nJVNaa',
  },
  subscription: {
    priceId: {
      monthly: 'price_1On0XeFLZGJRcZ2m6WSD7rqA',
      yearly: 'price_1Oo09QFLZGJRcZ2mzuNIeuvJ',
    },
  },
};

export const Products = isDevelopment ? TestProductIds : ProductionProductIds;

export const UnhurdProductPrices = {
  playlistPitch: 6.99,
  subscribedPlaylistPitch: 6.29,
  socialMediaBookingFee: 7.99,
  subscription: {
    monthly: {
      pricePerMonth: 9.99,
      pricePerYear: 119.88,
    },
    yearly: {
      pricePerMonth: 8.33,
      pricePerYear: 99.99,
    },
  },
};

export const DefaultSubscriptionFeatureFlagPayload = [
  {
    period: 'yearly',
    price: 99.99,
    pricePerMonth: 8.33,
    stripePriceId: Products.subscription.priceId.yearly,
    applePriceId: 'unhurd_pro_annual_001',
  },
  {
    period: 'monthly',
    price: 9.99,
    pricePerMonth: 9.99,
    stripePriceId: Products.subscription.priceId.monthly,
    applePriceId: 'unhurd_pro_001',
  },
];
